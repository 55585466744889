import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import Container from "../../../packages/Container/src";
import Div from "../../../packages/Div/src";
import { Heading, P } from "../../../packages/Typography/src";

var ListingPageHeaderPattern = function ListingPageHeaderPattern() {
  return React.createElement(PatternLayout, null, React.createElement(Div, {
    bg: "grey.50"
  }, React.createElement(Container, {
    size: "md",
    py: "3rem"
  }, React.createElement(Heading, {
    as: "h1",
    my: "0"
  }, "Page title"), React.createElement(P, {
    size: "lg",
    mt: "0.75rem",
    mb: "0"
  }, "Choose a topic to explore more."))));
};

export default ListingPageHeaderPattern;